<template>
    <div class="main-content">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>统计报表</BreadcrumbItem>
                <BreadcrumbItem>销售商机</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="filter">
            <Form label-colon inline>
                <FormItem>
                    <DatePicker type="daterange" v-model="filter.myDateRange" placeholder="请选择商机产生周期" separator=" 至 "></DatePicker>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.userId" placeholder="请选择用户" filterable clearable>
                        <Option v-for="item in userArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <deptTreeSelect v-model="filter.departmentId" :options="departmentOpts" placeholder="请选择所属组织" noOptionsText="暂无数据" noResultsText="没有匹配数据" clearable />
                </FormItem>
                <FormItem>
                    <Select v-model="filter.customerId" placeholder="请选择客户名称" filterable clearable>
                        <Option v-for="item in customerArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.type" placeholder="请选择商机类型" filterable clearable>
                        <Option v-for="item in typeArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.source"  placeholder="请选择商机来源" filterable clearable label-in-value>
                        <Option v-for="item in sourceArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.status" placeholder="请选择状态" filterable clearable>
                        <Option value="洽谈中">洽谈中</Option>
                        <Option value="已签约">已签约</Option>
                        <Option value="已开票">已开票</Option>
                        <Option value="已回款">已回款</Option>
                        <Option value="已结项">已结项</Option>
                        <Option value="需求暂停">需求暂停</Option>
                        <Option value="需求终止">需求终止</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="doSearching">搜索</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="resetting">重置</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" icon="md-cloud-download" @click="handleExport" v-if="permissionButton.export">导出</Button>
                </FormItem>
            </Form>
        </div>
        <div class="table">
            <div class="table-list">
                <Table border stripe :columns="columns" :data="dataList" :loading="loading"></Table>
            </div>
            <div class="table-footer">
                <Row>
                    <Col class="total" span="6">共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页</Col>
                    <Col class="pagenation" span="18">
                        <Page :page-size="pageSize" :total="total" :current="pageNum"
                              :page-size-opts="[15, 20, 50, 100]"
                              @on-change="changePage" @on-page-size-change="changPageSize" show-sizer show-elevator/>
                    </Col>
                </Row>
            </div>
        </div>
    </div>
</template>

<script>
import permission from '@/common/permission'
import {reqGetDictionaryListByCode, reqGetUserList} from "../../../api/system-api";
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import deptTreeSelect from "@riophae/vue-treeselect";
import {reqGetDepartmentTreeOpts, reqGetCustomerPerUser, reqGetOpportunityReport, reqExportOpportunityReport} from "../../../api/admin-api";

export default {
    name: 'customerReport',
    components: {deptTreeSelect},
    data() {
        return {
            filter: {
                myDateRange: null,
                userId: null,
                departmentId: null,
                customerId: null,
                type: null,
                source: null,
                status: null,
            },
            userArray: [],
            departmentOpts: [],
            customerArray: [],
            typeArray: [],
            sourceArray: [],
            loading: false,
            dataList: [],
            permissionButton: {
                export: false,
            },
            total: 0,
            pages: 1,
            pageSize: 20,
            pageNum: 1,
            columns: [
                {
                    title: '用户',
                    key: 'createByName',
                    width: 120,
                },
                {
                    title: '所属组织',
                    key: 'departmentName',
                    tooltip: 'true',
                    width: 150,
                },
                {
                    title: '客户名称',
                    key: 'customerName',
                    tooltip: 'true',
                    minWidth: 300,
                },
                {
                    title: '商机类型',
                    key: 'typeName',
                    align: 'center',
                    width: 100,
                },
                {
                    title: '商机创建时间',
                    key: 'createTime',
                    align: 'center',
                    width: 180,
                },
                {
                    title: '商机来源',
                    key: 'sourceName',
                    align: 'center',
                    width: 100,
                },
                {
                    title: '商机状态',
                    key: 'status',
                    align: 'center',
                    width: 100,
                },
                {
                    title: '商机金额',
                    width: 100,
                    render: (h, params) => {
                        return h('span', params.row.amount.toFixed(2))
                    }
                },
                {
                    title: '新增商机数',
                    align: 'center',
                    children: [{
                        title: '提交合同商机数',
                        key: 'countsOfContract',
                        align: 'center',
                        width: 130,
                    }, {
                        title: '回款商机数',
                        key: 'countsOfReceive',
                        align: 'center',
                        width: 130,
                    }, {
                        title: '业绩生效商机数',
                        key: 'countsOfPerformance',
                        align: 'center',
                        width: 130,
                    }]
                }
            ],
        }
    },
    methods: {
        init() {
            // 用户列表
            reqGetUserList().then((res) => {
                this.userArray = res.data;
            }).catch(() => {
                this.userArray = [];
            })

            // 所属组织
            reqGetDepartmentTreeOpts().then(res => {
                this.departmentOpts = res.data.data;
            }).catch(()=>{
                this.departmentOpts = [];
            })

            // 商机、客户来源
            reqGetDictionaryListByCode({code:'customer_source'}).then((res)=>{
                this.sourceArray = res.data.data
            }).catch(()=>{
                this.sourceArray = []
            })

            // 客户列表
            reqGetCustomerPerUser().then((res) => {
                this.customerArray = res.data;
            }).catch(() => {
                this.customerArray = []
            })

            // 商机类型
            reqGetDictionaryListByCode({code: 'opportunity_type'}).then((res) => {
                this.typeArray = res.data.data
            }).catch(() => {
                this.typeArray = []
            })
        },
        //分页点击，切换页面
        changePage(val) {
            this.pageNum = val;
            this.getOpportunityReport();
        },
        //每页显示的数据条数
        changPageSize(numOfRows) {
            //实时获取当前需要显示的条数
            this.pageSize = numOfRows;
            this.getOpportunityReport();
        },
        doSearching() {
            this.pageNum = 1;
            this.getOpportunityReport();
        },
        getOpportunityReport() {
            this.loading = true;
            this.dataList = [];
            let startDate = ((this.filter.myDateRange && this.filter.myDateRange.length > 0 && this.filter.myDateRange[0]) ? this.$moment(this.filter.myDateRange[0]).format('YYYY-MM-DD') : null);
            let endDate = ((this.filter.myDateRange && this.filter.myDateRange.length > 1 && this.filter.myDateRange[1]) ? this.$moment(this.filter.myDateRange[1]).format('YYYY-MM-DD') : null);
            let params = Object.assign({
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                startDate: startDate,
                endDate: endDate,
            }, this.filter);

            reqGetOpportunityReport(params).then((res) => {
                this.loading = false;
                this.pageNum = res.data.data.current
                this.pages = res.data.data.pages
                this.total = res.data.data.total
                this.dataList = res.data.data.records;
            }).catch(() => {
                this.loading = false;
                this.dataList = [];
            });
        },
        resetting() {
            this.loading = true;
            this.dataList = [];
            this.filter.myDateRange= null;
            this.filter.userId= null;
            this.filter.departmentId= null;
            this.filter.customerId= null;
            this.filter.type= null;
            this.filter.source= null;
            this.filter.status= null;
            let startDate = ((this.filter.myDateRange && this.filter.myDateRange.length > 0 && this.filter.myDateRange[0]) ? this.$moment(this.filter.myDateRange[0]).format('YYYY-MM-DD') : null);
            let endDate = ((this.filter.myDateRange && this.filter.myDateRange.length > 1 && this.filter.myDateRange[1]) ? this.$moment(this.filter.myDateRange[1]).format('YYYY-MM-DD') : null);
            let params = Object.assign({
                pageNum: 1,
                pageSize: 20,
                startDate: startDate,
                endDate: endDate,
            }, this.filter);

            reqGetOpportunityReport(params).then((res) => {
                this.loading = false;
                this.pageNum = res.data.data.current
                this.pages = res.data.data.pages
                this.total = res.data.data.total
                this.dataList = res.data.data.records;
            }).catch(() => {
                this.loading = false;
                this.dataList = [];
            });
        },
        handleExport() {
            this.$Modal.confirm({
                title: '导出销售商机',
                content: '确定导出销售商机查询结果？',
                width: '320',
                onOk: () => {
                    let startDate = ((this.filter.myDateRange && this.filter.myDateRange.length > 0 && this.filter.myDateRange[0]) ? this.$moment(this.filter.myDateRange[0]).format('YYYY-MM-DD') : null);
                    let endDate = ((this.filter.myDateRange && this.filter.myDateRange.length > 1 && this.filter.myDateRange[1]) ? this.$moment(this.filter.myDateRange[1]).format('YYYY-MM-DD') : null);
                    this.filter.startDate = startDate;
                    this.filter.endDate = endDate;
                    reqExportOpportunityReport(this.filter).then((res)=>{
                        let filename = 'Opportunity.xlsx';
                        const disposition = res.headers['content-disposition'];
                        if (typeof disposition !== 'undefined') {
                            let deDisposition = decodeURIComponent(disposition);
                            filename = deDisposition.substring(deDisposition.indexOf('filename=') + 9, deDisposition.length);
                        }

                        let blob = new Blob([res.data], {type: 'application/vnd.ms-excel;charset=utf-8'}) //type这里表示xlsx类型
                        let downloadElement = document.createElement('a')
                        let href = window.URL.createObjectURL(blob) //创建下载的链接
                        downloadElement.href = href
                        downloadElement.download = filename //下载后文件名
                        document.body.appendChild(downloadElement)
                        downloadElement.click() //点击下载
                        document.body.removeChild(downloadElement) //下载完成移除元素
                        window.URL.revokeObjectURL(href) //释放掉blob对象
                    }).catch((err)=>{
                        this.$Message.error('导出失败')
                    })
                },
            })
        }
    },
    mounted() {
        let permissionId = this.$route.query.permissionId;
        permission.getPermissionButton(permissionId, this.permissionButton);
        this.init();
        this.getOpportunityReport();
    }
}
</script>
